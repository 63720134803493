.coming-soon-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url('https://www.example.com/background-image.jpg') no-repeat center center fixed;
    background-size: cover;
  }
  
  .coming-soon-content {
    flex: 1;
    text-align: center;
    color: white;
    padding: 20px;
  }
  
  .coming-soon-text {
    font-size: 4rem;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-in-out;
  }
  
  .coming-soon-subtext {
    font-size: 1.5rem;
    animation: fadeIn 4s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  