body {
    background-color: #282c34;
    min-height: 100vh;
  }
  
  .card:hover {
    border: 2px solid #fdd000;
  }
  
  .card-link {
    text-decoration: none; /* Supprimer la bordure des liens */
  }
  
  .card-link:hover {
    text-decoration: none; /* Supprimer la bordure des liens lors du survol */
  }
  
  .grid-item {
    flex-basis: 20%; /* Afficher 5 éléments par ligne */
    max-width: 20%; /* Limiter la largeur de chaque élément */
    margin-bottom: 20px; /* Espacement entre les lignes */
  }
  
  .card {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    position: relative;
    height: 100%;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); /* Ajouter une ombre */
  }
  
  .card-media {
    background: #333333; /* Couleur de fond */
    object-fit: cover;
    height: 50px; /* Hauteur de l'image */
  }
  
  .card-content {
    position: relative;
  }
  
  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
  }
  
  @media screen and (max-width: 600px) {
    .grid-item {
      flex-basis: calc(50% - 20px); /* Afficher 2 éléments par ligne sur les petits écrans */
      max-width: calc(50% - 20px); /* Limiter la largeur de chaque élément sur les petits écrans */
    }
  
    .grid-item:nth-child(2n+1) {
      margin-right: 0; /* Supprimer la marge à droite pour le premier élément de chaque ligne sur les petits écrans */
    }
  }
  
  #footer {
    background-color: #212327;
    color: #ffffff;
    padding: 0px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .site-footer-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .site-identity {
    text-align: center; /* Centre le texte horizontalement */
  }
  
  .site-title a {
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
  }
  
  .site-version {
    color: #ae2424;
    margin-left: auto; /* Aligner à droite */
  }
      